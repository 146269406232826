import { StatusOptions } from '@/models/interface/Common'
import { currencyMask } from '@/models/interface/Masks'

export default {
  name: 'Media Package',
  sections: [
    {
      name: 'Package Information',
      fields: [
        {
          name: 'Package Name',
          type: 'text',
          target: 'name',
          rules: 'required|min:3|max:255',
          description: 'Enter package name.',
        },
        {
          name: 'Program Name',
          type: 'text',
          target: 'program_name',
          rules: 'max:255',
          description: 'Enter package name.',
        },
        {
          name: 'Status',
          type: 'picker',
          target: 'status',
          rules: 'required',
          description: 'If inactive it can\'t be linked with new Media Plans.',
          options: StatusOptions,
        },
        /* {
          name: 'Package Branding Image',
          type: 'image',
          target: 'image',
          file: 'file',
          description:
            '(Optional) Image can be used for package branding on building IOs & Media Plans.',
        }, */
        {
          name: 'Package Description',
          type: 'area',
          target: 'description',
          description: 'Enter package description.',
        },
        {
          name: 'Ad Units',
          type: 'adunit',
          target: 'adunit_ids',
          rules: 'required',
        },
        {
          name: 'Overwrite Price',
          target: 'overwrite_rate',
          type: 'checkbox',
          description: 'If yes, allows you to set a price for the package.',
        },
        {
          name: 'Package Price',
          target: 'rate',
          type: 'number',
          mask: currencyMask,
          description: 'Default package price',
        },
      ],
    },
  ],
}
