import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import MediaPackage from '@/models/MediaPackage'

const { user } = getModule(SystemtModule)

export default [
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
    tdClass: 'align-middle',
  },
  {
    key: 'description',
    label: 'Description',
    sortable: true,
    show: false,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'overwrite_rate',
    label: 'Price Overwrite',
    sortable: true,
    show: true,
    type: 'badge',
    formatter: (value: boolean) => (value ? 'Yes' : 'No'),
    color: (media_package: MediaPackage) => (media_package.overwrite_rate ? 'info' : 'secondary'),
    tdClass: 'align-middle text-center',
  },
  {
    key: 'rate',
    label: 'Rate',
    sortable: true,
    show: true,
    type: 'currency',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    show: true,
    type: 'badge',
    color: (media_package: MediaPackage) => media_package.status_color,
    formatter: (value: any, key: string, media_package: MediaPackage) => media_package.status,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    show: false,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    show: true,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: 'Actions',
    sortable: false,
    show: true,
    type: 'action',
    actions: [
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
        show: () => user && user.can('insertion_order', 'edit'),
      },
      {
        icon: 'copy',
        title: 'Clone',
        event: 'clone',
        show: () => user && user.can('insertion_order', 'edit'),
      },
      {
        icon: 'pause',
        title: 'Deactivate',
        event: 'deactivate',
        show: (media_package: MediaPackage) => media_package.status === 'active',
      },
      {
        icon: 'play',
        title: 'activate',
        event: 'activate',
        show: (media_package: MediaPackage) => media_package.status === 'inactive',
      },
    ],
  },
]
