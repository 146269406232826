

































import {
  Vue, Component, Prop, Watch, Ref,
} from 'vue-property-decorator'
import FormInput from '@/components/FormInput/FormInput.vue'
import Widget from '@/components/Widget/Widget.vue'
import { clone as _clone } from 'lodash'
// @ts-ignore
import LiquorTree from 'liquor-tree'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import ColorManager from '@/models/interface/ColorManager'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import DataForm from '@/components/DataForm/DataForm.vue'
import MediaPackage from '@/models/MediaPackage'
import formData from './form'

Vue.use(LiquorTree)

@Component({
  components: {
    DataForm,
    FormInput,
    CheckboxInput,
    Widget,
    VueSlider,
  },
})
export default class MediaPackageForm extends Vue {
  @Ref() readonly treeview: any

  @Prop({ default: '' })
  private id!: string

  @Prop({
    default: 'full',
  })
  private view_mode!: string

  public adunit_filter: string = ''

  public adunits = []

  public adunits_distribution: number[] = []

  public busy = false

  public loading = true

  private model: MediaPackage = new MediaPackage()

  private slider = {
    marks: (val: number) =>
      (val % 10 === 0
        ? {
          label: `${val}%`,
        }
        : false),
    process: (dotpos: number[]) => {
      let ret = []
      for (let i = 0; i < dotpos.length; i++) {
        ret.push([
          i === 0 ? 0 : dotpos[i - 1],
          dotpos[i],
          { backgroundColor: ColorManager.getColorByIndex(i) },
        ])
      }
      return ret
    },
  }

  private get form() {
    return formData
  }

  public cancel() {
    if (this.view_mode == 'sidebar') {
      this.$emit('update:show_sidebar', false)
    } else {
      this.$router.push({ name: 'Users' })
    }
  }

  private getSliderDataByIndex(index: number) {
    const checked = this.treeview.checked()
    let value = (this.adunits_distribution[index]
        - (index === 0 ? 0 : this.adunits_distribution[index - 1]))
      / 100
    return {
      name: checked[index].data.text,
      value,
    }
  }

  public updateAdUnits() {
    this.model.adunit_ids = this.treeview.checked().map((x: any) => x.id)

    if (this.model.adunit_ids.length > 0) {
      let base = 100 / this.model.adunit_ids.length
      let distribution = []
      for (let i = 0; i < this.model.adunit_ids.length; i++) {
        distribution.push(base * (i + 1))
      }
      this.adunits_distribution = distribution
    }
  }

  public onSubmit(model: MediaPackage) {
    this.busy = true
    model.save().then(response => {
      this.busy = false
      if (response.status == 200) {
        this.$router.push({ name: 'MediaPackages' })
      }
    })
  }

  public init() {
    this.loading = true
    const { query } = this.$route
    if (this.id) {
      MediaPackage.find(this.id).then(media_package => {
        if (media_package instanceof MediaPackage) {
          this.model = media_package
          this.loading = false
        }
      })
    } else if (query.from && typeof query.from === 'string') {
      MediaPackage.find(query.from).then(media_package => {
        if (media_package instanceof MediaPackage) {
          this.model = media_package.clone()
          this.model.id = ''
          this.loading = false
        }
      })
    } else {
      this.loading = false
    }
  }

  public created() {
    this.init()
  }
}
